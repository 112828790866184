.phoneNoDecoration {
  text-decoration: none;
}

.phoneNoDecoration:hover {
  color: #69c37b;
  scale: 1.1;
}
.printerr {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 80dvw;
  z-index: 5;
}
.printed {
  display: flex;
  align-items: center;
  gap: 15px;
}
