.mainProfileDiv {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 85%;
}

.firstDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}

.menus {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.dp {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  justify-content: center;
}

.secondDiv {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirdDiv {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lowerUpdateDiv {
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.navBtns {
  width: 100%;
  background: #69c37b;
  border: none;
}

.verticaLine {
  border-left: 1px solid #000; /* Adjust color and width as needed */
  height: 100%; /* Adjust height as needed */
  margin: 0 10px; /* Adjust spacing as needed */
}

.updateButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
